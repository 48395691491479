import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import SEO from "../components/SEO/seo"
// import Layout from "../components/layout"
import {
  PrivacyContainer,
  PrivacyTitle,
  PrivacySubTitle,
  PrivacyText,
  PrivacyItalic,
  TermsList,
  TermUl,
} from "../components/privacyTerms"

const StyledLink = styled(Link)`
  text-decoration: none;
  color: #33cdd1;
`

export default ({location}) => (
  <>
    <SEO title="Terms Of Services" description="Terms of services of Groupboss" pathname={location.pathname} />
    <PrivacyContainer>
      <PrivacyTitle>Terms of Service</PrivacyTitle>
      <PrivacyItalic>Last updated : 11 March 2020</PrivacyItalic>
      <PrivacyText>
        The following terms and conditions (the “Agreement”) govern all use of
        the Singularity Corporation (Groupboss) website the{" "}
        <span>
          <StyledLink to="/">(https://groupboss.io/) </StyledLink>
        </span>{" "}
        and its related services (“the Service”) subject to your compliance with
        the terms and conditions (“Terms of Service/TOS”) set forth below.
      </PrivacyText>
      <PrivacyText>
        Groupboss is a product of Singularity Corporation. Both of these will be
        used synonymously in all all Policies.
      </PrivacyText>
      <PrivacyText>
        Please read the following carefully.
        <br />
        Singularity Corporation (Groupboss) reserves the right to update and
        change the Terms of Service at any time without notice. All new updates,
        features, and amendments that enhance the current Service, including the
        release of new features and resources, shall be subject to the “Terms of
        Service”. Continued use of the Service after any such changes shall
        constitute your consent to said changes.
      </PrivacyText>
      <PrivacyText>
        If you are a company, organization, institution, head of a group of
        people, you are referred to as “Client”. If you are the end-user of the
        Service, you are referred to as a “User”. The Agreement is binding to
        Groupboss and you (Client and/or User). Violation of any of the terms
        below will result in the termination of your account. If you do not
        agree to the Agreement or any part of it, do not use the Site and
        Service.
      </PrivacyText>

      <PrivacySubTitle>Registration</PrivacySubTitle>
      <TermUl>
        <TermsList>
          For registering you must be human. Accounts registered by bots and
          other automated methods are not permitted.
        </TermsList>
        <TermsList>
          You must register on the Site to access the Service, and enjoy all the
          benefits of Groupboss. While registering you agree to provide
          complete, true and accurate information, as may be required.
        </TermsList>
        <TermsList>
          Upon registering for the service you have to provide an email address
          and a password. You are responsible for maintaining the
          confidentiality of your account, user id, and password.
        </TermsList>
        <TermsList>
          You shall never use someone else’s Groupboss account without
          permission from that User.
        </TermsList>
        <TermsList>
          You must notify Groupboss immediately of any breach of security or
          unauthorized use of your account.
        </TermsList>
        <TermsList>
          Although Groupboss will not be liable for losses caused by any
          unauthorized use of your account, you may be liable for the losses of
          Groupboss or others due to such unauthorized use.
        </TermsList>
        <TermsList>
          This Agreement is invalid where prohibited by law and the right to
          access the Service is revoked in such jurisdictions.
        </TermsList>
        <TermsList>
          Groupboss may impose limits on certain features and services or
          restrict User’s access to parts or all of the Services without notice
          or liability.
        </TermsList>
        <TermsList>
          Groupboss may change, suspend or discontinue the Services, fees,
          charges etc at any time, including the availability of any feature.
        </TermsList>
        <TermsList>
          You certify to Groupboss that you have full power and authority to
          enter into the Agreement and if registering on behalf of a company or
          other entity, that you have been conferred full authority to bind your
          principal or company.
        </TermsList>
        <TermsList>
          If you are an individual you shall be at least 18 years of age.
        </TermsList>
        <TermsList>
          You also certify that you are legally permitted to use the Service,
          and you take full responsibility for the selection and use of the
          Service.
        </TermsList>
      </TermUl>

      <PrivacySubTitle>Termination</PrivacySubTitle>
      <PrivacyText>
        You hereby acknowledge that you shall use the Service solely for lawful
        purposes and complying with the Agreement. Violation of any of the terms
        will result in the termination of your account. Groupboss will not be
        responsible for the content posted on the Service. You agree to use the
        Service at your own risk. The Service is provided on an “as is” and “as
        available” basis.
      </PrivacyText>
      <PrivacyText>
        You acknowledge that you have the required legal capacity to use the
        Service and be liable on the execution of the Agreement. You must not
        violate any laws in your jurisdiction. Groupboss reserves the right to
        refuse service to anyone for any reason at any time
      </PrivacyText>
      <PrivacyText>
        Groupboss, in its sole discretion, has the right to suspend or terminate
        your account and refuse any and all current or future use of the
        Service, or any other Groupboss service, for any reason, at any time.
        Such termination of the Service will result in the deactivation or
        deletion of your Account or access to your Account, and the forfeiture
        and relinquishment of all content in your Account.
      </PrivacyText>

      <PrivacySubTitle>Modifications</PrivacySubTitle>
      <PrivacyText>
        Groupboss reserves the right to modify and discontinue, at any time and
        from time to time, temporarily or permanently, the Service (or any part
        thereof) with or without notice.
      </PrivacyText>
      <PrivacyText>
        Prices of all Services, including subscription plan fees of the Service,
        are subject to change without notice from us. Groupboss shall not be
        liable to you or to any third party for any modification, price change,
        suspension, or discontinuance of the Service.
      </PrivacyText>
      <PrivacyText>
        You shall be responsible for reviewing and becoming familiar with any
        such modifications. Use of the Service by you following such
        modifications constitutes your acceptance of changes as modified.
      </PrivacyText>

      <PrivacySubTitle>Payments and Fees</PrivacySubTitle>
      <PrivacyText>
        Groupboss may save your credit card and/or PayPal information and use it
        for all future charges, which will automatically be charged unless you
        notify us. For recurring fees, Groupboss will charge your credit card on
        the time interval. When the Client uses a Service that has a fee, the
        Client will be charged then current fees, which we may change from time
        to time. Groupboss reserves the right to change its billing methods.
      </PrivacyText>
      <PrivacyText>
        By filling your payment information through the Site, you are confirming
        that you are legally entitled to use the means of payment tendered. In
        the case of card payments, you are either the cardholder or have the
        cardholder’s express permission to utilize the card to effect payment.
      </PrivacyText>
      <PrivacyText>
        The Service is billed in advance monthly. Should you find any change or
        amendment unacceptable, you may opt to cancel your membership without
        however any right to demand a refund for fees that have already accrued
        before such cancellation. All fees are exclusive of all taxes, levies,
        or duties imposed by taxing authorities, and you shall be responsible
        for payment of all such taxes, levies, or duties.
      </PrivacyText>
      <PrivacyText>
        If a billing cycle starts on date X of a given month it will end on date
        X of the next month irrespective of the number of days in those months.
        Date X is calculated on your first payment. For any upgrade or downgrade
        in plan level, your credit card/payment method that you provided will
        automatically be charged the new rate for the rest of your billing cycle
        and the previously charged amount will not be refunded. From next time,
        the billing cycle charges will be based on your changed plan.
      </PrivacyText>
      <PrivacyText>
        There will be no refunds or credits for partial months of Service, or
        refunds for months unused with an open account. Groupboss may choose to
        provide refunds or credits for partial usage but is not legally
        bound/liable to do so.
      </PrivacyText>
      <PrivacyText>
        We may refuse to process a transaction for any reason or refuse the
        Service to anyone at any time at our sole discretion. We will not be
        liable to you or any third party because of refusing or suspending any
        transaction after processing has begun.
      </PrivacyText>
      <PrivacyText>
        Unless otherwise stated, all fees and payments are quoted in U.S.
        Dollars. The User is responsible for paying all fees, payments and
        applicable taxes associated with our Site and Services.
      </PrivacyText>
      <PrivacyText>
        f you cancel your subscription or your account is deleted, Groupboss
        will not refund any fees that may have accrued before the termination.
      </PrivacyText>

      <PrivacySubTitle>14 days Refund Policy</PrivacySubTitle>
      <PrivacyText>
        Customers shall enjoy a 14 days refund policy, These refunds can be
        claimed without any specific reason, it's up to the wish of the
        customer. 14 days starts from the day the charge was made to the
        customer.
      </PrivacyText>
      <PrivacyText>
        14 days starts from the day the charge was made to the customer.
      </PrivacyText>

      <PrivacySubTitle>Lifetime purchase/deal policy</PrivacySubTitle>
      <PrivacyText>
        The definition and conditions of lifetime purchase policy is described
        in (https://groupboss.io/lifetime-deal-policy/)
      </PrivacyText>
      <PrivacyText>
        Agreeing to terms of services implies that you also agree to Lifetime
        purchase policy if you purchase the plan.
      </PrivacyText>

      <PrivacySubTitle>Privacy Policy</PrivacySubTitle>
      <PrivacyText>
        Our detailed privacy policy is outlined in ( Privacy policy) . You are
        agreeing to this Terms and Conditions implies that you also agree to our
        privacy policy.
      </PrivacyText>

      <PrivacySubTitle>Prohibited uses of the service</PrivacySubTitle>
      <PrivacyText>
        We provide the Service to you solely for your private use. You undertake
        not to use the Service:
      </PrivacyText>
      <TermUl>
        <TermsList>for any unlawful purpose;</TermsList>
        <TermsList>
          in any way so that the Service is interrupted, damaged, rendered less
          efficient or the functionality of the Service is in any way impaired;
        </TermsList>
        <TermsList>
          in any way that may damage or disrupt another user’s computer;
        </TermsList>
        <TermsList>
          for the transmission, uploading or posting of any computer viruses or
          any harmful or deleterious files or programs;
        </TermsList>
        <TermsList>
          to transmit, upload or post any material which is defamatory,
          offensive, racist, vulgar, libellous or of an obscene or menacing
          character, or in such a way as to cause annoyance, inconvenience or
          needless anxiety;
        </TermsList>
        <TermsList>
          as a means to threaten, stalk, harass, abuse, or otherwise insult
          other users or to collect or store personal data about other users;
        </TermsList>
        <TermsList>
          in a manner which constitutes a violation or infringement of any
          person, firm or company’s rights (including, but not limited to,
          rights of copyright or confidentiality);
        </TermsList>
        <TermsList>
          to transmit any material for publicity, promotion and/or advertising
          without our prior written consent unless such transmission has been
          specifically requested by another user of the Service;
        </TermsList>
        <TermsList>
          to create a false identity with an intention to mislead others as to
          the identity of the sender or the origin of a message, including, but
          not limited to impersonating an Groupboss employee, manager, host or
          another user; or
        </TermsList>
        <TermsList>
          to transmit, upload, post or otherwise make available any solicited or
          unauthorized advertising, promotional materials, “junk mail”, “spam”,
          “chain letters”, “pyramid schemes” or any duplicative or unsolicited
          messages.
        </TermsList>
      </TermUl>
      <PrivacyText>
        Moreover, we do not control the content posted via you or other users on
        the Service and as such, do not guarantee the accuracy, integrity or
        quality of such content. By using the Service, you acknowledge that you
        may be exposed to content that is offensive or objectionable.
      </PrivacyText>
      <PrivacyText>
        Under no circumstances will we be liable in any way for any content or
        any damage incurred as a result of you accessing any content posted or
        otherwise transmitted via the Service.You acknowledge that we do not
        monitor or pre-view any content posted via you or other users of the
        Service, but that we shall have the right (but not the obligation) to
        refuse access to or move any content made available via the Service.
      </PrivacyText>

      <PrivacySubTitle>Limitation of liability</PrivacySubTitle>
      <PrivacyText>
        Groupboss shall not be responsible for any loss or damage to you or any
        third parties caused by the service or information contained in the
        service. You waive any and all claims you may have against Groupboss
        arising out of the performance or nonperformance of the service. You
        specifically waive any and all claims you may have against Groupboss as
        a result of incorrect information content displayed by the service or
        changes to content made by you.
      </PrivacyText>
      <PrivacyText>
        Groupboss shall not be liable for any direct, indirect, special,
        incidental, or consequential damages, whether based on contract or tort
        or any other legal theory, arising out of any use of the service or any
        performance of these terms, including, without limitation, damages for
        loss of profits, loss of data, business interruption or any other
        commercial damages or losses, arising out of or related to your use or
        inability to use the service, even if Groupboss has been advised of the
        possibility of such damages.
      </PrivacyText>
      <PrivacyText>
        In no event shall Groupboss’s total liability to you for all damages
        (other than as may be required by applicable law in cases involving
        personal injury) exceed the amount actually paid by you to Groupboss for
        the service in the 365 days immediately preceding the date that
        Groupboss receives notice of a claim in writing from you. The foregoing
        limitations will apply even if the above-stated remedy fails of its
        essential purpose.
      </PrivacyText>

      <PrivacySubTitle>Indemnity on Limitation of Services</PrivacySubTitle>
      <PrivacyText>
        The services of Groupboss are dependent on the act of other companies
        like Facebook. If for any unforeseen reason or due the changes in
        technology both internally or externally (Services like Facebook), the
        services of Groupboss becomes obsolete or unusable, Groupboss will not
        take any responsibility on such unforeseen events.
      </PrivacyText>
      <PrivacyText>
        In such case No refunds on lifetime subscription or other subscription
        will be made due to the event and No such demand shall be entertained.
      </PrivacyText>

      <PrivacySubTitle>Indemnity</PrivacySubTitle>
      <PrivacyText>
        You further undertake that in the event that you have any right, claim
        or action against any other user arising from the use of the Service,
        you shall pursue such right, claim or action independently of, and
        without recourse to us.
      </PrivacyText>
      <PrivacyText>
        You will fully indemnify and defend us against all claims, liability,
        damages, costs, and expenses, including legal fees, arising out of a
        breach of this Contract or any use of the Service, the Information or
        the Website by you.
      </PrivacyText>
      <PrivacyText>
        Service might be interrupted due to technology changes, cyber attacks,
        server maintenance or any other reasons. Groupboss does not warrantee a
        pre notice of service interruption in such case and has indemnity in
        case loss that might occur due to such event on customers end.
      </PrivacyText>
      <PrivacyText>
        You acknowledge that we have limited control over the nature or content
        of information or programs transmitted or received by you or other users
        using the Service and that we do not examine in any way the use to which
        you put the Service.
      </PrivacyText>
      <PrivacyText>
        You agree to fully indemnify us against any claims or legal proceedings
        arising in connection with your use of the Service which is brought or
        threatened against us by any other person.
      </PrivacyText>

      <PrivacySubTitle>Disclaimer of warranties</PrivacySubTitle>
      <PrivacyText>
        The Service is provided on an “as is” and “as available” basis. You
        expressly acknowledge and agree that the use of the service is at your
        sole risk. Groupboss does not warrant that:
      </PrivacyText>
      <TermUl>
        <TermsList>
          the service will meet your specific requirements. We may advertise the
          service from our best understanding of the value we provide or based
          on past/future features of the services, but it is your duty to verify
          whether it suits your needs or not, based on the use of the free
          trial.
        </TermsList>
        <TermsList>
          the service will be uninterrupted, timely, or error-free,
        </TermsList>
        <TermsList>
          the results that may be obtained from the use of the service will be
          accurate or reliable. You shall not rely exclusively on the service
          for any reason.
        </TermsList>
        <TermsList>
          the quality of any services, information, or other material purchased
          or obtained by you through the service will meet your expectations or
          as advertised.
        </TermsList>
      </TermUl>
      <PrivacyText>
        Any claim arising out of or related to use of the service shall be filed
        to Groupboss within 90 days after the claim or cause of action accrues
        otherwise, it will be considered that you have no claims.
      </PrivacyText>
      <PrivacyText>
        Groupboss hereby disclaims all warranties and conditions with respect to
        the service and anything related thereto, either express, implied or
        statutory, including, but not limited to, the implied warranties and/or
        conditions of merchantability, of satisfactory quality, of fitness for a
        particular purpose, of accuracy, of quiet enjoyment, and
        non-infringement of third party rights. No oral or written information
        or advice is given by Groupboss or its authorized representative shall
        create a warranty.
      </PrivacyText>

      <PrivacySubTitle>Data retention policy</PrivacySubTitle>
      <PrivacyText>
        Groupboss has data retention policy and the system might not store
        user’s data perpetually. User’s data are deleted after a certain time
        interval ranges from 1 month to 3 years or more. These policies are
        depended on types of data and subjected to change time to time. It’s
        user’s responsibility to keep updated with most recent data retention
        policy.
      </PrivacyText>

      <PrivacySubTitle>Trademarks</PrivacySubTitle>
      <PrivacyText>
        If you use any of our trademarks related to our Services, you must
        include a statement attributing that trademark to us. You must not use
        any of our trademarks in or as the whole or part of your own trademarks;
        in connection with activities or Services which are not ours; in a
        manner which may be confusing, misleading or deceptive; or in a manner
        that disparages us or our information, products or Services (including
        the Site).
      </PrivacyText>
      <PrivacyText>
        All software, visual and textual content, along with Groupboss created
        texts, scripts, graphics, interactive features and trademarks, Service
        marks and logos are owned by or licensed to Groupboss, and protected
        subject to copyright and other intellectual property rights under US,
        EU, foreign laws and international conventions. You may not display or
        use the Groupboss marks or any intellectual property owned by Groupboss
        in any manner without Groupboss’s prior written consent.
      </PrivacyText>

      <PrivacySubTitle>Law and Jurisdictions</PrivacySubTitle>
      <PrivacyText>
        If ever a dispute arises between you and Groupboss, we strongly
        encourage you to first contact us directly to seek a resolution. We will
        consider reasonable requests to resolve the dispute through alternative
        dispute resolution procedures, such as mediation or arbitration, as
        alternatives to litigation.
      </PrivacyText>
      <PrivacyText>
        Any dispute or claim arising out of or in connection with the Agreement
        or its formation (including non-contractual disputes or claims) shall be
        governed by the laws of Peoples Republic of Bangladesh. The state and/or
        federal courts residing in Dhaka, Bangladesh shall have exclusive
        jurisdiction over any dispute or claim arising out of this Agreement
        and/or the Service.
      </PrivacyText>
      <PrivacyText>
        In case of any confusion, Groupboss has the sole right to interpret this
        agreement.
      </PrivacyText>

      <PrivacySubTitle>General</PrivacySubTitle>
      <PrivacyText>
        You acknowledge that you have all necessary permits to grant us with
        User’s personal data to fulfil this Agreement.
      </PrivacyText>
      <PrivacyText>
        No agency, partnership, joint venture, employee-employer,
        franchisor-franchisee relationship, etc. is intended or created by this
        Agreement.
      </PrivacyText>
      <PrivacyText>
        If any provision of this Agreement is held to be invalid or
        unenforceable, such provision shall be struck and the remaining
        provisions shall be enforced. Headings are for reference purposes only
        and do not limit the scope or extent of such section. Our failure to act
        concerning a breach by you or others does not waive our right to act
        concerning subsequent or similar breaches. We do not guarantee we will
        take action against all breaches of this Agreement. Groupboss reserves
        the right at its sole discretion to transfer or assign this Agreement or
        any right or obligation under this Agreement at any time.
      </PrivacyText>
    </PrivacyContainer>
  </>
)
